<template>
  <div id="CostToDrivePanel">
    <div class="row">
      <div class="col-12 col-lg-6">
        <FeatureHeader
          name="Cost to Drive"
          :score="vehicle.Cost_Score"
          :reverse="true"
        />
        <p class="cost-to-drive-subheader">{{ subHeaderText }}</p>
      </div>
    </div>
    <div class="row cost-breakdown">
      <div
        class="col-12 col-lg-4 text-center pb-5 pb-lg-0"
        v-for="(feature, idx) in costFeatures"
        :key="idx"
      >
        <div class="row">
          <div class="col"><img :src="feature.imgSrc" /></div>
        </div>
        <div class="row">
          <div class="col">
            <label :style="{ color: feature.color }"
              >{{ toCurrency(feature.annualCost) }} -
              {{ toCurrency(feature.costPerMile, 3) }} / mile</label
            >
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label>Annual {{ feature.costType }} cost</label>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label :style="{ color: feature.color }"
              >{{ toCurrency(feature.totalSavings) }} -
              {{ toCurrency(feature.savingsPerMile, 3) }} / mile</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Annual savings vs. gasoline</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <CostChart
          :vehicle="vehicle"
          v-show="showChart"
          :height="chartHeight"
        />
      </div>
    </div>
  </div>
</template>
<script>
const FeatureHeader = () => import("Components/VehicleDetail/FeatureHeader");
const CostChart = () => import("Components/VehicleDetail/CostChart");
import { MetricCalculations } from "Services/MetricCalculations";
import common from "Mixins/common";

export default {
  props: {
    vehicle: Object,
  },
  data() {
    return {
      showChart: true,
      chartHeight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.setChartHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.setChartHeight);
  },
  mounted() {
    this.setChartHeight();
  },
  methods: {
    toggleChart() {
      this.showChart = !this.showChart;
    },
    setChartHeight() {
      this.chartHeight = window.outerWidth < 800 ? 300 : 75;
    },
  },
  computed: {
    subHeaderText() {
      let subHeaderText = "estimated based on national averages";
      if (this.stateName) {
        subHeaderText += ` and costs in ${this.stateName}`;
      }
      return subHeaderText;
    },
    stateName() {
      return this.$store.state.locationInfo?.address?.state_full;
    },
    location() {
      return this.$store.state.locationInfo;
    },
    electricityCost() {
      return MetricCalculations.getElectricityCostData(
        this.vehicle,
        this.location
      );
    },
    maintenanceCost() {
      return MetricCalculations.getMaintenanceCostData(
        this.vehicle,
        this.location
      );
    },
    insuranceCost() {
      return MetricCalculations.getInsuranceCostData(
        this.vehicle,
        this.location
      );
    },
    costFeatures() {
      return [
        {
          imgSrc: require(`Images/electricity_cost_icon.svg`),
          color: "#45a717",
          costType: "electrical",
          annualCost: this.electricityCost.annualElectricityCost,
          costPerMile: this.electricityCost.electricityCostPerMile,
          totalSavings: this.electricityCost.annualSavingsOverGasoline,
          savingsPerMile: this.electricityCost.totalSavingsOverGasolinePerMile,
        },
        {
          imgSrc: require(`Images/maintenance_cost_icon.svg`),
          color: "#3ccaca",
          costType: "maintenance",
          annualCost: this.maintenanceCost.evAnnualMaintenanceCost,
          costPerMile: this.maintenanceCost.evMaintenancePerMile,
          totalSavings: this.maintenanceCost.annualSavingsOverGasoline,
          savingsPerMile: this.maintenanceCost.totalSavingsOverGasolinePerMile,
        },
        {
          imgSrc: require(`Images/insurance_cost_icon.svg`),
          color: "#8b0000",
          costType: "insurance",
          annualCost: this.insuranceCost.evAnnualInsuranceCost,
          costPerMile: this.insuranceCost.evInsuranceCostPerMile,
          totalSavings: this.insuranceCost.annualSavingsOverGasoline,
          savingsPerMile: this.insuranceCost.totalSavingsOverGasolinePerMile,
        },
      ];
    },
  },
  components: {
    FeatureHeader,
    CostChart,
  },
  mixins: [common],
};
</script>
<style scoped lang="scss">
.cost-to-drive-subheader {
  position: relative;
  top: -5px;
  color: #3c68ca;
  font-weight: 800;
  font-family: $primary-header-font-family;
  font-size: 0.625rem;
}

.cost-breakdown label {
  color: #162b32;
  font-family: $primary-header-font-family;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0;
}
</style>
